import React from "react";
import { graphql } from "gatsby";

import SearchPage from "../../components/Search";
import Layout from "../../components/layout";
import Breadcrumb from "../../components/Breadcrumb";

export default function Search({ data, location, params, pageContext }) {
  return (
    <Layout
      meta={{
        ...data.wpPage.Meta,
        canonical: data.wpBrand.uri,
      }}
      path={data.wpBrand.uri}
      cta={"a"}
      themeOptions={data.wp.themeOptions}
    >
      <Breadcrumb
        type="page"
        current={{
          title: "Search results",
          slug: "search-results",
          uri: "/search",
        }}
      />
      <SearchPage
        brands={data.allWpBrand}
        state={{ query: data.wpBrand.name, category: "all" }}
      />
    </Layout>
  );
}

export const query = graphql`
  query($id: String) {
    wpBrand(id: { eq: $id }) {
      name
      uri
    }
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    allWpBrand {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;
